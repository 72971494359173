import(/* webpackMode: "eager", webpackExports: ["MainContainer"] */ "/tmp/app/apps/dop-employee-portal/components/MainContainer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["OrganizerProvider"] */ "/tmp/app/apps/dop-employee-portal/components/Providers/OrganizerProvider.tsx");
;
import(/* webpackMode: "eager" */ "/tmp/app/design/dist/pages/employee/layout/root.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/app/packages/ui-react/app/providers/ui/auth-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/app/packages/ui-react/app/providers/ui/dop-ui-root-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/app/packages/ui-react/app/providers/ui/next-app-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/app/packages/ui-react/shared/lib/i18n/client/translations-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["useTranslation"] */ "/tmp/app/packages/ui-react/shared/lib/i18n/client/use-translation.tsx");
